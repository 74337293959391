import React from 'react'
import { graphql } from 'gatsby'

import SeoPlugin from 'gatsby-plugin-wpgraphql-seo'
import Layout from '../components/Layout'
import Seo from '../components/Seo'

function NotFoundPage({ data, location }) {
  const siteTitle = data.site.siteMetadata.title

  return (
    <div tw="text-center inline-block w-full mx-auto lg:my-24">
      {/* <Seo title="404: Page Not Found" /> */}
      <SeoPlugin post={data} title="404: Page Not Found" />
      <h1>404: Page Not Found</h1>
      <p>The page you requested could not be found.</p>
    </div>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
